import React, { useRef, useCallback, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Box,
    Button,
    useTheme,
    TextField,
    Alert,
    useMediaQuery,
} from "@mui/material";
import {
    useGeneratePDFMutation,
    useGetEmployeeWorkHourCostsQuery,
} from "./employeeWorkHourCostsApiSlice";
import PageHeader from "components/PageHeader";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useDeleteAdvancePaymentsAfterGenerateProcessingSalaryPDFMutation } from "features/advancePayments/advancePaymentsApiSlice";
import { useDeleteAdjustmentsAfterGenerateProcessingSalaryPDFMutation } from "features/adjustments/adjustmentsApiSlice";
import Dialog from "@mui/material/Dialog";
import { RESPONSES } from "config/responses";
import dayjs from "dayjs";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import CustomTable from "components/CustomTable";
import { Add, Edit } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const EmployeeWorkHourCostsList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [
        openGeneratePDFRevisionConfirmationDialog,
        setOpenGeneratePDFRevisionConfirmationDialog,
    ] = useState(false);

    const handleOpenGeneratePDFRevisionConfirmationDialog = () => {
        setOpenGeneratePDFRevisionConfirmationDialog(true);
    };

    const handleCloseGeneratePDFRevisionConfirmationDialog = () => {
        setOpenGeneratePDFRevisionConfirmationDialog(false);
    };

    // const { data: employeeWorkHourCosts, isLoading } =
    //     useGetEmployeeWorkHourCostsQuery();

    const columns = useRef([
        {
            title: t("nInternal"),
            field: "employee.nInternal",
            filtering: true,
        },
        {
            title: t("name"),
            field: "employee.name",
            filtering: true,
        },
        {
            title: t("salaryType"),
            field: "employee.salaryType.type",
            filtering: true,
        },
        {
            title: t("monthOfProcessing"),
            field: "currentDate",
            filtering: true,
            render: (rowData) => {
                const currentDate = rowData.currentDate;
                return currentDate
                    ? dayjs(currentDate).format("YYYY-MM-DD")
                    : "";
            },
        },
    ]);

    const columnsMobile = useRef([
        {
            title: t("name"),
            field: "employee.name",
            filtering: true,
        },
        {
            title: t("monthOfProcessing"),
            field: "currentDate",
            filtering: true,
            render: (rowData) => {
                const currentDate = rowData.currentDate;
                return currentDate
                    ? dayjs(currentDate).format("YYYY-MM-DD")
                    : "";
            },
            hidden: true,
        },
    ]);

    const tableRef = useRef(null);
    const handleGetEmployeeWorkHourCosts = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const isHistoric = false;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? columns.current[orderBy.orderBy].field
                              : columnsMobile.current[orderBy.orderBy].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;

            const response = await dispatch(
                apiSlice.endpoints.getEmployeeWorkHourCostsPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                    isHistoric,
                })
            ).unwrap();
            return {
                data: response.employeeWorkHourCosts,
                page: query.page,
                totalCount: response.totalEmployeeWorkHourCosts || 0,
            };
        },
        [isNonMobile ? columns : columnsMobile]
    );

    const currentDate = new Date();
    const previousMonth = new Date(currentDate);
    previousMonth.setMonth(currentDate.getMonth() - 1);

    const actualDate = previousMonth.toLocaleString().split(",")[0]; // Obtém a data e hora atual no formato de string
    const processingDate =
        actualDate.split("/")[1] + "/" + actualDate.split("/")[2];

    const [openGeneratePDFDialog, setOpenGeneratePDFDialog] = useState(false);

    const handleOpenGeneratePDFDDialog = () => {
        setOpenGeneratePDFDialog(true);
    };

    const handleCloseGeneratePDFDialog = () => {
        setOpenGeneratePDFDialog(false);
    };

    const generatePDFSchema = yup.object().shape({
        email: yup
            .string()
            .email(t("invalidEmail"))
            .required(t("requiredField")),
    }); /* Set the employeeWorkHourCosts Schema */

    const initialValuesGeneratePDF = {
        email: "",
    }; /* Set the Form Initial Values */

    // apagar adiantamentos depois de gerar o processamento salarial
    const [deleteAdvancePaymentsAfterGenerateProcessingSalaryPDFMutation] =
        useDeleteAdvancePaymentsAfterGenerateProcessingSalaryPDFMutation();

    // apagar acertos depois de gerar o processamento salarial
    const [deleteAdjustmentsAfterGenerateProcessingSalaryPDFMutation] =
        useDeleteAdjustmentsAfterGenerateProcessingSalaryPDFMutation();

    const [generatePDFRevision] = useGeneratePDFMutation();
    const [generatePDF, { isLoading: isLoadingGeneratePDF }] =
        useGeneratePDFMutation();

    const handleFormGeneratePDFSubmit = async (values, onSubmitProps) => {
        try {
            const generatePDFData = {
                email: values.email,
                isToSend: true,
            };
            const { codeResult } = await generatePDF(generatePDFData).unwrap();

            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts.sucessToGenerateProcessingPDF
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToProcessingBecauseHaventProcessings
                ) {
                    handleSnackbarOpen(
                        t("invalidToProcessingBecauseHaventProcessings"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToProcessingBecauseSomeFuncHaventSalary
                ) {
                    handleSnackbarOpen(
                        t("invalidToProcessingBecauseSomeFuncHaventSalary"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts.invalidToProcessPDFRevision
                ) {
                    handleSnackbarOpen(
                        t("invalidToProcessPDFRevision"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts.invalidToProcessPDF
                ) {
                    handleSnackbarOpen(t("invalidToProcessPDF"), "error");
                } else {
                    handleSnackbarOpen(t("errorGeneratePDF"), "error");
                }
            } else {
                setOpenGeneratePDFDialog(false);
                deleteAdvancePaymentsAfterGenerateProcessingSalaryPDFMutation();
                deleteAdjustmentsAfterGenerateProcessingSalaryPDFMutation();
                handleSnackbarOpen(t("sucessToGeneratePDF"), "success");
            }
        } catch (err) {
            handleSnackbarOpen(t("errorGeneratePDF"), "error");
        }
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFilePreview = async () => {
        try {
            const generatePDFData = {
                email: "",
                isToSend: false,
            };
            const { codeResult, attachment } = await generatePDFRevision(
                generatePDFData
            ).unwrap();
            if (
                codeResult !==
                RESPONSES.employeeWorkHourCosts.sucessToProcessPDFRevision
            ) {
                if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToProcessingBecauseHaventProcessings
                ) {
                    handleSnackbarOpen(
                        t("invalidToProcessingBecauseHaventProcessings"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts
                        .invalidToProcessingBecauseSomeFuncHaventSalary
                ) {
                    handleSnackbarOpen(
                        t("invalidToProcessingBecauseSomeFuncHaventSalary"),
                        "error"
                    );
                } else if (
                    codeResult ===
                    RESPONSES.employeeWorkHourCosts.invalidToProcessPDFRevision
                ) {
                    handleSnackbarOpen(
                        t("invalidToProcessPDFRevision"),
                        "error"
                    );
                } else {
                    handleSnackbarOpen(t("errorGeneratePDF"), "error");
                }
            } else {
                const file = base64ToFile(
                    attachment.content,
                    attachment.fileName,
                    attachment.fileType
                );

                file.path = attachment.fileName;

                // event.stopPropagation();
                const base64Promises = convertToBase64(file);
                try {
                    const base64File = await base64Promises;

                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(file);
                    link.download = file.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setOpenGeneratePDFDialog(false);
                    handleSnackbarOpen(
                        t("sucessToProcessPDFRevision"),
                        "success"
                    );
                    handleCloseGeneratePDFRevisionConfirmationDialog();
                } catch (err) {
                    handleSnackbarOpen(err, "error");
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorGeneratePDF"), "error");
        }
    };

    function base64ToFile(base64Data, fileName, mimeType) {
        const uint8Array = new Uint8Array(
            atob(base64Data)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([uint8Array], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });

        return file;
    }

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {isLoadingGeneratePDF ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        title={t("processing")}
                        // subtitle={t("processingList")}
                    />
                    <Box
                        mt="40px"
                        display="flex"
                        minHeight="70vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile
                                    ? "column"
                                    : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                {
                                    backgroundColor: `${theme.palette.background.A} !important`,
                                },
                        }}
                    >
                        <CustomTable
                            tableRef={tableRef}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            title={t("processingList")}
                            columns={
                                isNonMobile
                                    ? columns.current
                                    : columnsMobile.current
                            }
                            getData={handleGetEmployeeWorkHourCosts}
                            style={{
                                width: "100%",
                                padding: "0 10px 0 10px",
                                margin: "0% 2% 1% 2%",
                                overflow: !isNonMobile ? "auto" : null,
                            }}
                            actions={[
                                {
                                    icon: () => <Add />,
                                    tooltip: "Adicionar",
                                    isFreeAction: true,
                                    onClick: () => {
                                        navigate(`${location.pathname}/new`);
                                        return Promise.resolve();
                                    },
                                },
                                {
                                    icon: () => <Edit />,
                                    tooltip: "Editar",
                                    isFreeAction: false,
                                    onClick: (event, rowData) => {
                                        navigate(
                                            `${location.pathname}/${rowData.id}-0`
                                        );
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            onRefresh={() =>
                                tableRef.current &&
                                tableRef.current.onQueryChange()
                            }
                        />
                    </Box>
                    <Box display="flex" gap="10px" marginTop="20px">
                        <LoadingButton
                            fullWidth
                            // onClick={(event) => handleFilePreview(event)}
                            onClick={
                                handleOpenGeneratePDFRevisionConfirmationDialog
                            }
                            // loading={isLoadingGeneratePDFRevision}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                marginBottom: "20px",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("generatePDFRevision")}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            onClick={handleOpenGeneratePDFDDialog}
                            loading={isLoadingGeneratePDF}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                marginBottom: "20px",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("generatePDF")}
                        </LoadingButton>

                        {/* GENERATE PROCESSAMENTO SALARIAL*/}
                        <Dialog
                            open={openGeneratePDFDialog}
                            onClose={handleCloseGeneratePDFDialog}
                            maxWidth="800px"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Formik
                                onSubmit={handleFormGeneratePDFSubmit}
                                initialValues={initialValuesGeneratePDF}
                                validationSchema={generatePDFSchema}
                                enableReinitialize={true}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    resetForm,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            display="grid"
                                            gridTemplateRows="repeat(2, auto)"
                                            gap="20px"
                                            backgroundColor={
                                                theme.palette.background.D
                                            }
                                            borderRadius="5px"
                                            p="2rem"
                                            width={
                                                !isNonMobile ? "100%" : "40rem"
                                            }
                                        >
                                            <PageHeader
                                                title={t("processSalary")}
                                                subtitle=""
                                            />
                                            <Box
                                                display="flex"
                                                gap="20px"
                                                flexDirection="column"
                                                width="100%"
                                                mt="10px"
                                            >
                                                <Box
                                                    display="grid"
                                                    gridTemplateColumns={
                                                        !isNonMobile
                                                            ? "repeat(1, 1fr)"
                                                            : "repeat(2, 1fr)"
                                                    }
                                                    gap="10px"
                                                >
                                                    <TextField
                                                        label={t(
                                                            "monthOfProcessing"
                                                        )}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={processingDate}
                                                        name="processingDate"
                                                        error={
                                                            Boolean(
                                                                touched.processingDate
                                                            ) &&
                                                            Boolean(
                                                                errors.processingDate
                                                            )
                                                        }
                                                        helperText={
                                                            touched.processingDate &&
                                                            errors.processingDate
                                                        }
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        readOnly={true}
                                                    />
                                                    <TextField
                                                        label={t("email")}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        name="email"
                                                        error={
                                                            Boolean(
                                                                touched.email
                                                            ) &&
                                                            Boolean(
                                                                errors.email
                                                            )
                                                        }
                                                        helperText={
                                                            touched.email &&
                                                            errors.email
                                                        }
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            {/* BUTTONS */}
                                            <LoadingButton
                                                fullWidth
                                                type="submit"
                                                loading={isLoadingGeneratePDF}
                                                sx={{
                                                    width: "100%",
                                                    p: "1rem",
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.neutral
                                                        .white,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.light,
                                                    },
                                                }}
                                            >
                                                {t("sendSalaryProcessing")}
                                            </LoadingButton>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Dialog>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert
                                onClose={handleCloseSnackBar}
                                severity={snackbarSeverity}
                                sx={{
                                    mt: "10px",
                                    backgroundColor:
                                        snackbarSeverity === "success"
                                            ? "#10781F"
                                            : "#ff000082",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#fff",
                                }}
                            >
                                {snackbarMsg}
                            </Alert>
                        </Snackbar>
                        <Dialog
                            open={openGeneratePDFRevisionConfirmationDialog}
                            onClose={
                                handleCloseGeneratePDFRevisionConfirmationDialog
                            }
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t(
                                    "generatePDFRevisionConfirmationDialogTitle"
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(
                                        "generatePDFRevisionConfirmationDialogDescription"
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    sx={{
                                        backgroundColor: theme.palette.alt.red,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.alt.lightRed,
                                        },
                                    }}
                                    onClick={
                                        handleCloseGeneratePDFRevisionConfirmationDialog
                                    }
                                >
                                    {t("noMessage")}
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: theme.palette.neutral.white,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary.light,
                                        },
                                    }}
                                    onClick={handleFilePreview}
                                    autoFocus
                                >
                                    {t("yesMessage")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default EmployeeWorkHourCostsList;
