import React, { useRef, useCallback, useEffect } from "react";
import { Box, useTheme, Alert, useMediaQuery, Button } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingSpinner from "components/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import * as yup from "yup";
import {
    useGenerateGraphsMutation,
    useRecallCostsMutation,
} from "./employeeCostsByWorkApiSlice";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { RESPONSES } from "config/responses";
import { useDispatch } from "react-redux";
import CustomTable from "components/CustomTable";
import { Add, Edit } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const EmployeeCostsByWorkList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const [errMsg, setErrMsg] = useState(""); /* Set the Error Message State */

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [
        openGenerateGraphsConfirmationDialog,
        setOpenGenerateGraphsConfirmationDialog,
    ] = useState(false);

    const handleOpenGenerateGraphsConfirmationDialog = () => {
        setOpenGenerateGraphsConfirmationDialog(true);
    };

    const handleCloseGenerateGraphsConfirmationDialog = () => {
        setOpenGenerateGraphsConfirmationDialog(false);
    };

    const [
        openCostsRecallConfirmationDialog,
        setOpenCostsRecallConfirmationDialog,
    ] = useState(false);

    const handleOpenCostsRecallConfirmationDialog = () => {
        setOpenCostsRecallConfirmationDialog(true);
    };

    const handleCloseCostsRecallConfirmationDialog = () => {
        setOpenCostsRecallConfirmationDialog(false);
    };

    const initialValues = {
        monthToRecall: "",
    }; /* Set the Forms Initial Values */

    const schema = yup.object().shape({
        monthToRecall: yup.date().required(t("requiredField")),
    }); /* Set the Vehicle Planning forms Schema */

    const columns = useRef([
        { title: "Id", field: "id", hidden: true },
        {
            field: "employee.nInternal",
            title: t("nInternal"),
            filtering: true,
        },
        {
            field: "employee.name",
            title: t("employeeName"),
            filtering: true,
        },
        {
            field: "workPlanning.work.name",
            title: t("planningWork"),
            filtering: true,
        },
        {
            field: "employeeCostMonth",
            title: t("monthlyEmployeeCost"),
            filtering: true,
            render: (rowData) => {
                const employeeCostMonth = rowData.employeeCostMonth;
                return employeeCostMonth
                    ? dayjs(employeeCostMonth).format("YYYY-MM-DD")
                    : "";
            },
        },
    ]);

    const columnsMobile = useRef([
        {
            field: "employee.name",
            title: t("employeeName"),
            filtering: true,
        },
        {
            field: "employeeCostMonth",
            title: t("monthlyEmployeeCost"),
            filtering: true,
            render: (rowData) => {
                const employeeCostMonth = rowData.employeeCostMonth;
                return employeeCostMonth
                    ? dayjs(employeeCostMonth).format("YYYY-MM-DD")
                    : "";
            },
        },
    ]);

    const tableRef = useRef(null);
    const refreshTable = useCallback(() => {
        if (tableRef.current) {
            tableRef.current.onQueryChange()
        }
    }, [tableRef])
    const handleGetEmployeeCostsByWork = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                    query.filters.map((filter) => ({
                        column: filter.column.field,
                        operator: filter.operator,
                        value: filter.value,
                    }))
                )
                : null;
            const isHistoric = false;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                    query.orderByCollection.map((orderBy) => ({
                        column: isNonMobile
                            ? columns.current[orderBy.orderBy].field
                            : columnsMobile.current[orderBy.orderBy].field,
                        orderDirection: orderBy.orderDirection,
                    }))
                )
                : null;

            const response = await dispatch(
                apiSlice.endpoints.getEmployeeCostsByWorkPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                    isHistoric,
                })
            ).unwrap();
            return {
                data: response.employeeCostsByWork,
                page: query.page,
                totalCount: response.totalEmployeeCostsByWork || 0,
            };
        },
        [isNonMobile ? columns : columnsMobile]
    );

    const [generateGraphs, { isLoadingGenerateGraphs }] =
        useGenerateGraphsMutation();

    const [recallCosts, { isLoadingRecallCosts }] = useRecallCostsMutation();

    const [generateGraphsLoading, setGenerateGraphsLoading] = useState();

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            setGenerateGraphsLoading(true);
            const { codeResult } = await generateGraphs(values).unwrap();
            if (
                codeResult !==
                RESPONSES.employeeCostsByWork.sucessToGenerateGraphs
            ) {
                setGenerateGraphsLoading(false);
                handleSnackbarOpen(t("errorToGenerateGraphs"), "error");
            } else {
                setGenerateGraphsLoading(false);
                handleCloseGenerateGraphsConfirmationDialog();
                handleSnackbarOpen(t("sucessToGenerateGraphs"), "success");
            }
            refreshTable()
        } catch (err) {
            setGenerateGraphsLoading(false);
            handleSnackbarOpen(t("errorToGenerateGraphs"), "error");
        }
    };

    const [loadingRecall, setLoadingRecall] = useState(false);
    const handleFormCostsRecallSubmit = async (values, onSubmitProps) => {
        try {
            setLoadingRecall(true);
            const { codeResult } = await recallCosts(values).unwrap();

            if (
                codeResult !== RESPONSES.employeeCostsByWork.sucessToRecallCosts
            ) {
                handleSnackbarOpen(t("errorRecallCosts"), "error");
            } else {
                handleCloseCostsRecallConfirmationDialog();
                handleSnackbarOpen(t("sucessToRecallCosts"), "success");
            }
            setLoadingRecall(false);
            refreshTable()
        } catch (err) {
            setLoadingRecall(false);
            handleSnackbarOpen(t("errorRecallCosts"), "error");
        }
    };

    useEffect(() => {
        //refresh table on start
        refreshTable()
    }, [])

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {generateGraphsLoading || loadingRecall || isLoadingRecallCosts ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        title={t("employeeCostByWork")}
                    // subtitle={t("costsList")}
                    />
                    <Box
                        mt="40px"
                        minHeight="70vh"
                        display="flex"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile
                                    ? "column"
                                    : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                            {
                                backgroundColor: `${theme.palette.background.A} !important`,
                            },
                        }}
                    >
                        <CustomTable
                            tableRef={tableRef}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            title={t("costsList")}
                            columns={
                                isNonMobile
                                    ? columns.current
                                    : columnsMobile.current
                            }
                            getData={handleGetEmployeeCostsByWork}
                            style={{
                                width: "100%",
                                padding: "0 10px 0 10px",
                                margin: "0% 2% 1% 2%",
                                overflow: !isNonMobile ? "auto" : null,
                            }}
                            actions={[
                                {
                                    icon: () => <Add />,
                                    tooltip: "Adicionar",
                                    isFreeAction: true,
                                    onClick: () => {
                                        navigate(`${location.pathname}/new`);
                                        return Promise.resolve();
                                    },
                                },
                                {
                                    icon: () => <Edit />,
                                    tooltip: "Editar",
                                    isFreeAction: false,
                                    onClick: (event, rowData) => {
                                        navigate(
                                            `${location.pathname}/${rowData.id}`
                                        );
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            onRefresh={() =>
                                tableRef.current &&
                                tableRef.current.onQueryChange()
                            }
                        />
                    </Box>
                    <Box display="flex" gap="10px" marginTop="20px">
                        <LoadingButton
                            fullWidth
                            // disabled={true}
                            onClick={handleOpenCostsRecallConfirmationDialog}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                marginBottom: "20px",
                                marginTop: "20px",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("recallCosts")}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            onClick={handleOpenGenerateGraphsConfirmationDialog}
                            sx={{
                                width: "100%",
                                p: "1rem",
                                marginBottom: "20px",
                                marginTop: "20px",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.white,
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {t("generateGraphs")}
                        </LoadingButton>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openCostsRecallConfirmationDialog}
                        onClose={handleCloseCostsRecallConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Formik
                            onSubmit={handleFormCostsRecallSubmit}
                            initialValues={initialValues}
                            validationSchema={schema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                resetForm,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={
                                            !isNonMobile
                                                ? "repeat(1, 1fr)"
                                                : "repeat(1, auto)"
                                        }
                                        gap="20px"
                                        backgroundColor={
                                            theme.palette.background.D
                                        }
                                        borderRadius="5px"
                                        p="2rem"
                                    >
                                        <PageHeader
                                            title={t("recallCosts")}
                                            subtitle=""
                                        />
                                        <Box
                                            display="flex"
                                            gap="20px"
                                            flexDirection="column"
                                            width="100%"
                                            mt="10px"
                                        >
                                            <Box
                                                display="grid"
                                                gridTemplateColumns={
                                                    "repeat(1, 1fr)"
                                                }
                                                gap="10px"
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        views={[
                                                            "year",
                                                            "month",
                                                        ]}
                                                        label={t("recallMonth")}
                                                        value={
                                                            values.monthToRecall
                                                        }
                                                        format="MM/YYYY"
                                                        onChange={(value) =>
                                                            setFieldValue(
                                                                "monthToRecall",
                                                                value
                                                            )
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>
                                        {errMsg && (
                                            <Alert
                                                severity="error"
                                                sx={{
                                                    mt: "10px",
                                                    backgroundColor: "#540e0e",
                                                    color: "#FFFFFF",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {errMsg}
                                            </Alert>
                                        )}
                                        {/* BUTTONS */}
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            loading={isLoadingRecallCosts}
                                            sx={{
                                                width: "100%",
                                                p: "1rem",
                                                backgroundColor:
                                                    theme.palette.primary.main,
                                                color: theme.palette.neutral
                                                    .white,
                                                "&:hover": {
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .light,
                                                },
                                            }}
                                        >
                                            {t("recallCosts")}
                                        </LoadingButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                    <Dialog
                        open={openGenerateGraphsConfirmationDialog}
                        onClose={handleCloseGenerateGraphsConfirmationDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("generateGraphsConfirmationDialogTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t(
                                    "generateGraphsConfirmationDialogDescription"
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={
                                    handleCloseGenerateGraphsConfirmationDialog
                                }
                            >
                                {t("noMessage")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                onClick={handleFormSubmit}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
};

export default EmployeeCostsByWorkList;
